import type UserResource from '~/api/resource/UserResource';
import MenuDropdownDTO from '~/dto/common/menu/MenuDropdownDTO';
import MenuDropdownItemDTO from '~/dto/common/menu/MenuDropdownItemDTO';
import type TransportOrganizerResource from '~/api/resource/TransportOrganizerResource';

export function toDTO(user: UserResource, currentTransportOrganizer: TransportOrganizerResource): MenuDropdownDTO {
  const menuDropdownItems: MenuDropdownItemDTO[] = [];

  for (const transportOrganizer of user.transportOrganizers) {
    menuDropdownItems.push(new MenuDropdownItemDTO(transportOrganizer.id, transportOrganizer.label));
  }

  const currentMenuDropdownItem = new MenuDropdownItemDTO(currentTransportOrganizer.id, currentTransportOrganizer.label);
  return new MenuDropdownDTO(user.email, menuDropdownItems, currentMenuDropdownItem);
}
