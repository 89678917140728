<template>
  <li>
    <NuxtLink :to="route">
      <div :class="getLinkClass()">
        <OptiagoIcon class="fill-gray-800" :name="'optiago:' + icon" size="20px" mode="svg" />
        <span v-if="!isCollapse" :class="getSpanClass()" role="tooltip">
          {{ label }}
        </span>
      </div>
    </NuxtLink>
  </li>
</template>

<script setup lang="ts">
  import { useMenuStore } from '~/stores/common/MenuStore';
  import OptiagoIcon from '~/components/ui/OptiagoIcon.vue';
  const { isCollapse } = storeToRefs(useMenuStore());

  const props = defineProps<{
    label: string;
    isActive: boolean;
    icon: string;
    route: string;
  }>();

  const getLinkClass = () => {
    const classList = ['flex', 'items-center', 'gap-x-2', 'py-2', 'px-2.5', 'text-base', 'text-gray-700', 'font-semibold', 'rounded-lg', 'hover:bg-gray-100'];

    if (isCollapse.value) {
      classList.push('justify-center');
    }

    if (props.isActive) {
      classList.push('bg-gray-200');
    }

    return classList.join(' ');
  };

  const getSpanClass = () => {
    return isCollapse.value ? '' : 'whitespace-nowrap overflow-hidden text-ellipsis delay-300';
  };
</script>
