<template>
  <div>
    <LoadingBase
      v-show="loading && skeleton == null"
      title="Chargement en cours..."
      title-class="font-bold text-4xl"
      subtitle="Merci de patienter"
      sub-title-class="font-light text-2xl"
      container-class="h-screen bg-gray-50"
    />
    <component :is="modalComponent" v-show="loading && skeleton != null" />
    <div v-show="!loading" class="bg-gray-50">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
  import { useGlobalLoadingStore } from '~/stores/common/GlobalLoadingStore';
  import type { Component } from 'vue';

  const { loading, skeleton } = storeToRefs(useGlobalLoadingStore());
  const modalComponent = shallowRef<Component | null>(null);

  onMounted(async () => {
    if (skeleton.value && import.meta.client) {
      const c = await import(`~/components/common/skeleton/${skeleton.value}.vue`);
      modalComponent.value = c.default;
    }
  });
</script>
